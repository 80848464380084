<template>
	<div class="pa-0 wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<!-- 타이틀 -->
			<v-col
				align="center"
				class="pa-0"
			>
				<v-row class="ma-0 py-5 justify-center main_tit">
					<v-col
						class="pa-0"
						cols="auto"
						align-self="center"
					>
						<v-img
							width="24"
							eager
							:src="require('@/assets/img/careflo/icon_care_flo.svg')"
						/>
					</v-col>
					<v-col
						class="pa-0 ml-1"
						align-self="center"
						cols="auto"
					>
						케어플로 서비스
					</v-col>
				</v-row>
			</v-col>

			<!-- 바코드 업로드 -->
			<v-col
				cols="12"
				class="pa-4 mb-3 section"
			>
				<h3 class="mb-4">바코드 업로드</h3>
				<div class="mb-5 py-5 px-8 capture_viewer">
					<v-col
						class="pa-0 photo"
						align="center"
					>
						<canvas></canvas>
					</v-col>
				</div>
				<v-row class="ma-0 notice">
					<v-col
						class="pa-0"
						cols="auto"
					>
						<v-img
							width="20"
							eager
							:src="require('@/assets/img/careflo/icon_error.svg')"
						/>
					</v-col>
					<v-col
						class="pa-0"
						cols="10"
					>
						<div class="ml-2">촬영이 완료된 사진은 임의로 수정이 불가합니다. 수정 필요 시 고객센터로 문의주세요.</div>
					</v-col>
				</v-row>
			</v-col>

			<!-- 일련번호 확인 -->
			<v-col
				cols="12"
				class="pa-4 mb-5 section"
			>
				<h3 class="mb-4">상세정보</h3>
				<div class="px-4 serial_number">
					<v-row
						v-if="barcode"
						class="py-3 ma-0"
					>
						<v-col
							class="pa-0 th"
							cols="5"
						>
							일련번호
						</v-col>
						<v-col
							class="pa-0 td"
							cols="7"
							align="end"
						>
							{{ barcode }}
						</v-col>
					</v-row>
					<v-row class="py-3 ma-0">
						<v-col
							class="pa-0 th"
							cols="5"
						>
							서비스구분
						</v-col>
						<v-col
							class="pa-0 td"
							cols="7"
							align="end"
						>
							{{ care_type }}
						</v-col>
					</v-row>
					<v-row class="py-3 ma-0">
						<v-col
							class="pa-0 th"
							cols="5"
						>
							업로드 시간
						</v-col>
						<v-col
							class="pa-0 td"
							cols="7"
							align="end"
						>
							{{ reg_date }}
						</v-col>
					</v-row>
					<v-row class="py-3 ma-0">
						<v-col
							class="pa-0 th"
							cols="5"
						>
							인증횟수
						</v-col>
						<v-col
							class="pa-0 td"
							cols="7"
							align="end"
						>
							{{ item.count }}회
						</v-col>
					</v-row>
				</div>

				<v-row
					v-if="CAREFLO_GET_AUTH_USER.event === 'Y'"
					class="ma-0 mt-4 justify-center"
				>
					<v-col
						class="pa-0"
						cols="6"
					>
						<CommonButtonsButton01
							rounded
							class="btn_event_result"
							name="이벤트 당첨 확인 >"
							color="#00592D"
							height="36"
							@click="handlerDialogEventShowClick"
						/>
						<CommonDialogDialogDynamicBasic
							:dialog="dialogEvent"
							:title="'이벤트 당첨 확인'"
							:item="item"
							:components-path="'home/careflo/event'"
							:width="800"
							@close="closeDialog"
						/>
					</v-col>
				</v-row>
			</v-col>

			<!-- 인증 접속하기 -->
			<v-col class="pa-0 px-4">
				<div class="center_info">
					<v-row class="ma-0 mb-15 justify-center">
						<v-col
							class="pa-0"
							cols="auto"
							align="end"
						>
							<div class="mr-1 txt_1">고객센터</div>
						</v-col>
						<v-col
							class="pa-0"
							cols="auto"
						>
							<div class="ml-2">
								<v-col class="pa-0 txt_2">1899-2837</v-col>
								<v-col class="pa-0 txt_3">평일 9:00 ~ 17:00(공휴일 외)</v-col>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
	name: 'CareFloStoreUpload',
	data: () => ({
		barcode: null,
		care_type: null,
		reg_date: null,
		item: { count: 0 },
		dialogEvent: false,
	}),
	computed: {
		...mapGetters(['CAREFLO_GET_AUTH_USER', 'CAREFLO_GET_BARCODE_ITEM']),
	},
	watch: {},
	async created() {
		const param = { operator_hand: this.CAREFLO_GET_AUTH_USER.hand }
		await this.CAREFLO_ACT_STORE_EVENT_CHECK_CNT(param).then(res => {
			console.log('CAREFLO_ACT_STORE_EVENT_CHECK_CNT', res)
			this.item.count = res.cnt
		})
	},
	mounted() {
		this.barcode = this.CAREFLO_GET_BARCODE_ITEM.barcode || ''
		this.care_type = this.CAREFLO_GET_BARCODE_ITEM.care_type === 'one' ? '원케어' : '풀케어'
		this.reg_date = this.CAREFLO_GET_BARCODE_ITEM.reg_date

		const canvas = document.querySelector('canvas')
		const ctx = canvas.getContext('2d')
		const image = new Image()
		image.onload = function () {
			canvas.width = image.width
			canvas.height = image.height
			ctx.drawImage(image, 0, 0)
		}
		image.src = this.CAREFLO_GET_BARCODE_ITEM.image
	},
	methods: {
		...mapActions(['CAREFLO_ACT_STORE_EVENT_CHECK_CNT']),
		handlerDialogEventShowClick() {
			this.dialogEvent = true
		},

		closeDialog() {
			this.dialogEvent = false
		},
	},
}
</script>

<style scoped lang="scss">
.main_tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	line-height: 1;
	color: $color_font;
}
.sub_tit {
	font-size: $font_normal;
	color: $color_gray_7;
}
.section {
	background-color: $color_white;
	h3 {
		color: $color_font;
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
	.desc {
		font-size: $font_normal;
		color: $color_gray_5;
	}
	.item {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}
.center_info {
	.txt_1 {
		font-size: $font_normal;
		color: $color_gray_6;
	}
	.txt_2 {
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_gray_8;
	}
	.txt_3 {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.capture_viewer {
	background-color: $color_gray_1;
	border: 1px solid $color_gray_3;
	border-radius: 8px;
	.photo {
		color: $color_white;
		canvas {
			max-width: 224px !important;
			max-height: 300px !important;
			aspect-ratio: 10/16;
		}
	}
}
.notice {
	color: $color_gray_8;
	font-size: $font_sm;
	word-break: keep-all;
}
.serial_number {
	border: 1px solid $color_gray_2;
	border-radius: 4px;
	font-size: $font_normal;
	.th {
		color: $color_gray_5;
	}
	.td {
		color: $color_font;
	}
	.row {
		border-bottom: 1px solid $color_gray_2;
		&:last-child {
			border-bottom: none;
		}
	}
}

.btn_event_result {
	:deep(.v-btn__content) {
		font-size: $font_normal !important;
	}
}
</style>
